/**
 * Please don't put any additional types into this file.
 * Most things should instead live in a more specifically named file.
 */
import { Static, String, Union } from "runtypes";

import { SpecialVersionType } from "./enums";

export type HexVersionString =
  | (string & { __type: "HexVersionString" })
  | SpecialVersionType;

export const JsonSerializedParameterValueContents = String.withBrand(
  "JsonSerializedParameterValueContents",
);
export type JsonSerializedParameterValueContents = Static<
  typeof JsonSerializedParameterValueContents
>;

export const ParameterName = String.withBrand("ParameterName");
export type ParameterName = Static<typeof ParameterName>;

export const SecretName = String.withBrand("SecretName");
export type SecretName = Static<typeof SecretName>;

export const VariableName = Union(ParameterName, SecretName);
export type VariableName = Static<typeof VariableName>;

export const KernelImage = String.withBrand("KernelImage");
export type KernelImage = Static<typeof KernelImage>;

export const KernelImageTag = String.withBrand("KernelImageTag");
export type KernelImageTag = Static<typeof KernelImageTag>;

export const KernelImageUrl = String.withBrand("KernelImageUrl");
export type KernelImageUrl = Static<typeof KernelImageUrl>;

export const GitSyncStateKey = String.withBrand("GitSyncStateKey");
export type GitSyncStateKey = Static<typeof GitSyncStateKey>;
