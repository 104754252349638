import { last } from "lodash";
import hashObject from "object-hash";
import { Literal, Null, Record, Static, String, Union } from "runtypes";

import { CellType } from "../enums.js";
import {
  DataConnectionId,
  DataSourceTableId,
  SemanticDatasetName,
} from "../idTypeBrands.js";
import { HEX_HIDDEN_PREFIX } from "../languageUtils.js";

export const NoCodeCellTypeLiteral = Union(
  Literal(CellType.CHART),
  Literal(CellType.PIVOT),
  Literal(CellType.DISPLAY_TABLE),
  Literal(CellType.FILTER),
  Literal(CellType.EXPLORE),
);
export type NoCodeCellType = Static<typeof NoCodeCellTypeLiteral>;

/**
 * Helper function for generating a valid dataframe name for a cell w/ a sql
 * config. Returns a unique dataframe name given a data connection Id, path to
 * table, and limit.
 */
export const generateDataframeNameForDataSourceTable = (
  dataSourceTableConfig: Omit<DataSourceTableConfig, "dataframeName">,
): string => {
  const { connectionId, pathToTable } = dataSourceTableConfig;
  // we generate a hash to ensure that we only have
  // alphanumeric characters in the dataframe name
  const hash = hashObject({ connectionId, pathToTable });
  const dataframeName = `${HEX_HIDDEN_PREFIX}${hash}`;
  return dataframeName;
};

export const generateDataframeNameForSemanticDatasetInput = (
  semanticDatasetInput: SemanticDatasetInput,
): string => {
  const hash = hashObject({
    semanticDatasetName: semanticDatasetInput.semanticDatasetName,
  });
  return `${HEX_HIDDEN_PREFIX}_${hash}`;
};

export const DataSourceTableConfig = Record({
  dataframeName: String,
  connectionId: DataConnectionId,
  pathToTable: String,
  dataSourceTableId: DataSourceTableId,
});

export type DataSourceTableConfig = Static<typeof DataSourceTableConfig>;

export const SemanticDatasetInput = Record({
  type: Literal("SemanticDataset"),
  semanticDatasetName: SemanticDatasetName,
});

export type SemanticDatasetInput = Static<typeof SemanticDatasetInput>;

/**
 * This can either be a dataframe name or an object that contains
 * metadata on the warehouse table + dataframe that contains the output of
 * the query to the warehouse table.
 */
export const NoCodeCellDataframe = Union(DataSourceTableConfig, String);

export type NoCodeCellDataframe = Static<typeof NoCodeCellDataframe>;

// This is temporary - to start am just adding support for semantic datasets
// into the explore cell. We may need to change what we store here once we
// figure out the execution logic for querying semantic models.
export const ExploreCellDataframe = Union(
  NoCodeCellDataframe,
  SemanticDatasetInput,
);

export type ExploreCellDataframe = Static<typeof ExploreCellDataframe>;

/**
 * This is only used for file schema generation.
 */
export const NullableNoCodeCellDataframe = Union(NoCodeCellDataframe, Null);
export const NullableExploreCellDataframe = Union(ExploreCellDataframe, Null);

/**
 * This takes a no code cell datafarme input and returns a string input
 * parameter. For normal dataframe inputs, this is just the name of the
 * dataframe, but for WHNC inputs, we want the input to be the path to the
 * table, to mirror what we do for SQL cells.
 */
export function getNoCodeCellInputReference(
  dataframe: NoCodeCellDataframe,
): string {
  if (typeof dataframe === "string") {
    return dataframe;
  }
  // we strip the quotes to match the behavior of SQL cell references
  return stripQuotes(dataframe.pathToTable);
}

export function getDataframeName(dataframe: ExploreCellDataframe): string;
export function getDataframeName(
  dataframe: ExploreCellDataframe | null,
): string | null;
export function getDataframeName(
  dataframe: ExploreCellDataframe | null,
): string | null {
  if (typeof dataframe === "string" || dataframe == null) {
    return dataframe;
  } else if (SemanticDatasetInput.guard(dataframe)) {
    return generateDataframeNameForSemanticDatasetInput(dataframe);
  }
  return dataframe.dataframeName;
}

/**
 * Returns just the table name, with any special quoting characters removed.
 */
export function getDataframeDisplayName(dataframe: NoCodeCellDataframe): string;
export function getDataframeDisplayName(
  dataframe: NoCodeCellDataframe | null,
): string | null;
export function getDataframeDisplayName(
  dataframe: NoCodeCellDataframe | null,
): string | null {
  if (typeof dataframe === "string" || dataframe == null) {
    return dataframe;
  }

  return displayNameFromPathToTable(dataframe.pathToTable);
}

export function displayNameFromPathToTable(pathToTable: string): string {
  const lastPart = last(pathToTable.split(".")) ?? "";
  return stripQuotes(lastPart);
}

export function getPathToTableParts(dataframe: NoCodeCellDataframe): string[] {
  if (typeof dataframe === "string") {
    return [];
  }
  return dataframe.pathToTable.split(".").map(stripQuotes);
}

function stripQuotes(str: string): string {
  return str.replaceAll(new RegExp('["\\[\\]`]', "g"), "");
}
